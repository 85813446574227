import React, { useEffect, useRef, useState } from 'react';
import boop from './assets/boop.gif'
import './App.css';
import { freeze_gif } from './utils';

function App() {
  const [isBooping, setBooping] = useState(false)
  const [boopCount, setBoopCount] = useState(0)
  const imgRef = useRef<HTMLImageElement>(null)
  useEffect(() => {
    imgRef.current!.style.opacity = '0'
    setTimeout(() => {
      if (imgRef.current) {
        freeze_gif(imgRef.current)
        imgRef.current!.style.opacity = '1'
      }
    }, 500);
  }, [])

  useEffect(() => {
    if ([10, 20, 50, 100, 200, 500, 1000].find(elem => elem === boopCount)) {
      alert(`Поздравляем, вы сделали ${boopCount} бупов! Так держать!`)
    }
  }, [boopCount])

  const makeBoop = () => {
    if (isBooping) return
    setBooping(true)
    imgRef.current!.src = boop
    setTimeout(() => {
      setBooping(false)
      setBoopCount(prev => prev + 1)
      if (imgRef.current) {
        freeze_gif(imgRef.current)
      }
    }, 500);
  }

  return (
    <div className="App">
      <h2>Нажми на жеппу чтобы сделать
        <span style={{ color: 'red', marginLeft: 8, fontSize: 32 }}>Б</span>
        <span style={{ color: 'blue', fontSize: 32 }}>У</span>
        <span style={{ color: 'green', fontSize: 32 }}>П</span>
      </h2>
      <img onClick={makeBoop} ref={imgRef} src={boop} alt='boop' />
    </div>
  );
}

export default App;
